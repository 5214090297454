<template>
  <v-card class="mt-3 pb-4">
    <v-row justify="end">
      <v-col cols="auto">
        <mf-button color="primary" class="ma-4" label="Adicionar Botão" :disabled="disableButton" @click="openCreate"></mf-button>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" class="mt-0 px-5">
      <draggable v-model="homeButtons" class="mt-0" style="width: 100%;" :disabled="!enabled" :move="checkMove" @start="dragging = true" @end="finishMove">
        <v-expansion-panel v-for="button in homeButtons" :key="button.position" class="mt-3">
          <v-expansion-panel-header>
            <span>
              <v-icon class="mr-1">{{ getFormattedIcon(button) }}</v-icon>
              {{ button.title }}
            </span>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <form-button-new-app :button="button" :app-type="app.app_type" :index="getIndex(button)" :accountId="accountId" @refetch="refetchHomeButtons" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </draggable>
    </v-expansion-panels>
    <create-app-button :dialog="createDialog" :list-buttons="homeButtons" :app-type="app.app_type" :account-id="accountId" @close="closeCreate" />
    <create-new-app-button :dialog="dialogNewButton" :list-buttons="homeButtons" :app-type="app.app_type" :account-id="accountId" @close="closeCreate" />
  </v-card>
</template>

<script>
import { QUERY_AVAILABLE_ICONS, MUTATION_UPDATE_BUTTON_POSITION, QUERY_GET_BUTTONS } from '@/modules/accounts/graphql'
export default {
  components: {
    CreateAppButton: () => import('@/modules/accounts/components/app/CreateAppButton'),
    Draggable: () => import('vuedraggable'),
    CreateNewAppButton: () => import('@/modules/accounts/components/app/CreateNewAppButton.vue'),
    FormButtonNewApp: () => import('@/modules/accounts/components/app/FormButtonNewApp.vue')
  },
  props: {
    app: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    accountId: '',
    panel: 0,
    notEmptyRule: v => !!v || 'Campo obrigatório',
    homeButtons: [],
    editableButtons: {},
    createDialog: false,
    dialogNewButton: false,
    enabled: true,
    dragging: true,
    iconTypes: ['INTERNAL', 'EXTERNAL'],
    availableIcons: [],
    iconsOrder: [],
    list: []
  }),
  computed: {
    disableButton() {
      return this.app.app_type === 'default' ? !(this.availableIcons.length > 0) : false
    },
    getFormattedIcon() {
      return button => {
        if (button.icon_type === 'EXTERNAL') {
          return `fas fa-${button.icon}`
        }
        return `mdi-${button.icon}`
      }
    }
  },
  apollo: {
    adminGetAvailableIcons: {
      query: QUERY_AVAILABLE_ICONS,
      fetchPolicy: 'network-only',
      skip() {
        return !this.accountId
      },
      async update({ adminGetAvailableIcons }) {
        if (!adminGetAvailableIcons) return []
        this.availableIcons = adminGetAvailableIcons.icons
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountId: this.accountId
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    },
    adminGetDefaultHomeButtons: {
      query: QUERY_GET_BUTTONS,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          account_id: this.$route.params.id
        }
      },
      async update({ buttons }) {
        this.homeButtons = buttons.map(a => {
          return {
            ...a,
            token_reference: Math.random()
              .toString(36)
              .substr(2)
          }
        })
        this.list = this.homeButtons.map(a => {
          return { ...a }
        })
        this.homeButtons = this.homeButtons.sort((a, b) => a.position - b.position)
      },
      error(err) {
        console.log(err?.message)
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id'),
            accountid: this.$route.params.id
          },
          uri: this.$microservicesUrls['app_config']
        }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    getIndex(val) {
      if (this.app.app_type !== 'default') {
        let resp = this.list.find(e => {
          if (e.token_reference === val.token_reference) {
            return e
          }
        })
        return this.list.indexOf(resp)
      }
      return this.list.indexOf(val)
    },
    refetchHomeButtons() {
      this.$apollo.queries.adminGetDefaultHomeButtons.refetch()
    },
    openCreate() {
      this.dialogNewButton = true
    },
    closeCreate() {
      this.dialogNewButton = false
      this.$apollo.queries.adminGetDefaultHomeButtons.refetch()
    },
    checkMove: async function(e) {
      this.iconsOrder = e.relatedContext.list
    },
    finishMove: async function(e) {
      const [indexOrigin, futureOrigin] = [e.oldIndex, e.newIndex]
      if (indexOrigin === futureOrigin) return
      let upOrDown
      indexOrigin < futureOrigin ? (upOrDown = 'UP') : (upOrDown = 'DOWN')
      let buttons
      if (upOrDown === 'UP') {
        buttons = this.iconsOrder.map((icon, index) => {
          if (index > indexOrigin && index <= futureOrigin) {
            return {
              icon: icon.icon,
              position: index,
              index: this.getIndex(icon)
            }
          }
        })
        buttons = buttons.filter(icon => icon !== undefined)
        buttons.push({
          icon: this.iconsOrder[indexOrigin].icon,
          position: futureOrigin + 1,
          index: this.getIndex(this.iconsOrder[indexOrigin])
        })
      } else {
        buttons = this.iconsOrder.map((icon, index) => {
          if (index >= futureOrigin && index < indexOrigin) {
            return {
              icon: icon.icon,
              position: index + 2,
              index: this.getIndex(icon)
            }
          }
        })
        buttons = buttons.filter(icon => icon !== undefined)
        buttons.push({
          icon: this.iconsOrder[indexOrigin].icon,
          position: futureOrigin + 1,
          index: this.getIndex(this.iconsOrder[indexOrigin])
        })
      }
      let variables = this.setVaribles(buttons)
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_BUTTON_POSITION,
          variables,
          context: {
            headers: {
              authorization: localStorage.getItem('session_id'),
              accountId: this.accountId
            },
            uri: this.$microservicesUrls['app_config']
          }
        })
      } catch (err) {
        console.log(err.message)
        this.$alert({
          alert_message: 'Falha ao alterar a posição do botão',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
      this.dragging = false
    },
    setVaribles(items) {
      const result = {
        app_type: this.app.app_type,
        input: {
          account_id: this.accountId,
          buttons: items
        }
      }
      return result
    }
  }
}
</script>
